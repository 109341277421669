import * as React from 'react';
import PropTypes from 'prop-types';
import variables from '../variables.scss';

const sizes = {
  big: 180,
  small: 90,
  xs: 48,
};

const Rocket = ({ size = 'big' }) => (
  <svg
    width={sizes[size]}
    height={sizes[size]}
    viewBox="0 0 180 180"
    fill="none"
  >
    <path
      opacity={0.1}
      d="M94.618 21.36c-11.155-.552-13.794 7.448-23.94 1.831C60.53 17.575 54.978 4.23 44.09 1.253 37.087-.66 29.08-.93 23.437 4.42c-5.43 5.16-7.184 14.041-8.129 22.289-.708 6.208-1.127 12.738.82 18.549 1.349 4.034 3.746 7.424 5.404 11.289 5.767 13.444 1.691 30.022-4.56 43.149-2.93 6.157-6.333 12.038-8.596 18.588-2.263 6.55-3.309 14.066-1.33 20.753 1.963 6.633 6.639 11.606 11.703 15.107 10.288 7.11 22.406 9.147 34.233 10.299 26.167 2.553 52.475 1.447 78.711.341 9.711-.41 15.255-1.644 24.807-3.784 5.305-1.189 12.15-2.46 16-7 4.5-7.5 5-13.5 2.841-22-5.495-12.159-10.788-14.452-14.633-27.5-2.115-7.181 3.721-22.34 6.792-29 6.591-14.291-14.445-13.907-13.862-30.242.355-9.956-10.623 19.529-13.803-8.64-3.18-28.17 0-5.493-15.454-3.052-16.599 18.92-19.915-11.72-29.763-12.206z"
      fill={variables.midGreen}
    />
    <path
      d="M137.198 108.027c-4.866 4.866-10.013 9.264-15.488 13.147a123.479 123.479 0 01-30.225 16.003L42.822 88.516A123.505 123.505 0 0158.825 58.29c3.884-5.475 8.282-10.622 13.148-15.488 27.7-27.7 65.178-39.818 101.393-36.168 3.65 36.215-8.469 73.693-36.168 101.393z"
      fill={variables.gray}
      stroke={variables.black}
      strokeWidth={6}
    />
    <path
      d="M118.622 164.315c-12.914 12.914-33.875 12.914-46.79 0-5.66-5.662-8.842-12.868-9.544-20.307 9.92-1.029 19.745-3.369 29.197-6.831a123.479 123.479 0 0030.225-16.003c9.733 12.914 8.703 31.35-3.088 43.141zM35.992 117.712c-7.44-.702-14.645-3.883-20.306-9.545-12.914-12.914-12.914-33.875 0-46.79 11.79-11.79 30.226-12.82 43.14-3.087a123.504 123.504 0 00-16.003 30.226c-3.462 9.451-5.801 19.277-6.83 29.196z"
      fill={variables.gray}
      stroke={variables.black}
      strokeWidth={6}
    />
    <path
      d="M137.339 61.377c5.168-5.168 5.168-13.547 0-18.716-5.168-5.168-13.548-5.168-18.716 0-5.168 5.169-5.168 13.548 0 18.716 5.168 5.169 13.548 5.169 18.716 0z"
      fill={variables.gray}
      stroke={variables.black}
      strokeWidth={6}
    />
  </svg>
);

Rocket.propTypes = {
  size: PropTypes.oneOf(['big', 'small', 'xs']),
};

export default Rocket;
