import { LoremIpsum } from 'lorem-ipsum';

const loremIpsum = new LoremIpsum({
  wordsPerSentence: {
    max: 8,
    min: 4,
  },
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
});

export const products = [
  {
    _id: '5ed3f619c7dedd4948252ebe',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['yellow', 'orange'],
    price: 35.95,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711746/Fly-flowers/94_bwusfy.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e99',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 45,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/42_azmziv.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea7',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 18.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/58_sh5jse.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ebc',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 55,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711761/Fly-flowers/92_mbfgpy.jpg',
    collection: 'light',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e8c',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 20.95,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711756/Fly-flowers/10_tmmizx.jpg',
    collection: 'colorful',
    trending: true,
    recommended: true,
  },
  {
    _id: '5ed3f619c7dedd4948252e92',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange', 'white'],
    price: 12.95,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/21_udjpv8.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e8f',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange'],
    price: 20.95,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/18_r3d3q1.jpg',
    collection: 'colorful',
    trending: false,
    recommended: true,
  },
  {
    _id: '5ed3f619c7dedd4948252e8d',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange'],
    price: 30,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/13_y21dxl.jpg',
    collection: 'colorful',
    trending: false,
    recommended: true,
  },
  {
    _id: '5ed3f619c7dedd4948252e8e',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 40,
    occasions: ['love', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/17_qxnpwh.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb3',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 40,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/78_a8xyvg.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb2',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 20.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/77_nnouk9.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ead',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 55,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/67_zbnuam.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb4',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 45,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/80_gih0be.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb9',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 55,
    occasions: ['decoration', 'birthday', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711761/Fly-flowers/87_sk3dtk.jpg',
    collection: 'light',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e98',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange', 'yellow'],
    price: 25.95,
    occasions: ['love', 'decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/40_kftomo.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea9',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 35,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/63_psjjnu.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb0',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'orange'],
    price: 45.95,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/73_s2rkc0.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e8a',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['yellow'],
    price: 22,
    occasions: ['birthday', 'decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711756/Fly-flowers/5_xa6ana.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e90',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 50.95,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/19_f3dblx.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e95',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white', 'yellow'],
    price: 38,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/27_jfepr9.jpg',
    collection: 'colorful',
    trending: false,
    recommended: true,
  },
  {
    _id: '5ed3f619c7dedd4948252e9b',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white', 'yellow'],
    price: 60,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/44_arznzg.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eae',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 45.95,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/68_zgizia.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e9a',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 45.95,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/43_tresxd.jpg',
    collection: 'colorful',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea5',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['yellow', 'red'],
    price: 45,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/56_a6toxg.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb8',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 25,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711761/Fly-flowers/86_kq5ely.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e9c',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 35.95,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/47_heic1c.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e8b',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 30,
    occasions: [],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711756/Fly-flowers/7_odtx2k.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eac',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 10.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/66_czdvlt.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e9d',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'yellow', 'red'],
    price: 50.95,
    occasions: ['birthday', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/48_jjnbsb.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea6',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red'],
    price: 35.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/57_hwciev.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eaf',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 40,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/69_mo8yhu.jpg',
    collection: 'light',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ec1',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 20,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711745/Fly-flowers/97_boo3pt.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eaa',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 35.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/64_mcxgp2.jpg',
    collection: 'light',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea1',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red', 'yellow'],
    price: 32,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/52_d44q4v.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea3',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 22,
    occasions: ['love', 'decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/54_qxr0c7.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ebd',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['yellow'],
    price: 20.95,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711745/Fly-flowers/93_ak2rco.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e93',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange', 'yellow'],
    price: 65,
    occasions: ['birthday', 'love', 'decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/22_bbnvh4.jpg',
    collection: 'colorful',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea0',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white'],
    price: 25.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/51_p1mt93.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb5',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange'],
    price: 30,
    occasions: ['decoration', 'birthday', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/82_so1k3a.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea2',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'red', 'yellow'],
    price: 25,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/53_ixz4zk.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb1',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 20.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/76_cy2owg.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb7',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 40.95,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/85_pvy4ef.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e91',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 30,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/20_vcmx3k.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ebb',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 35,
    occasions: ['decoration', 'birthday', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711761/Fly-flowers/91_nnjati.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ec0',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink'],
    price: 55,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711745/Fly-flowers/96_h7bjju.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e94',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange', 'pink'],
    price: 55,
    occasions: ['birthday', 'love', 'decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/23_qxx5vu.jpg',
    collection: 'colorful',
    trending: true,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e97',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red', 'white'],
    price: 12.5,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/31_kg7gel.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ebf',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red', 'pink'],
    price: 55,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711746/Fly-flowers/95_rtn94a.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea4',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 40.95,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/55_yxmztx.jpg',
    collection: 'light',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e9e',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 30,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711758/Fly-flowers/49_blkkqb.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eab',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['orange'],
    price: 35.95,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/65_tbyerc.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eba',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['white', 'pink'],
    price: 40,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711761/Fly-flowers/88_y27orp.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252eb6',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red'],
    price: 55,
    occasions: ['love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711760/Fly-flowers/83_jcaymz.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e96',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['pink', 'white'],
    price: 20,
    occasions: ['decoration'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711757/Fly-flowers/30_kuarja.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252e9f',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['red', 'yellow'],
    price: 35.95,
    occasions: ['decoration', 'love'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/50_ulomx0.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
  {
    _id: '5ed3f619c7dedd4948252ea8',
    title: `${loremIpsum.generateSentences(1)}`,
    description: `${loremIpsum.generateParagraphs(1)}`,
    colors: ['yellow', 'orange'],
    price: 22,
    occasions: ['decoration', 'birthday'],
    url: 'https://res.cloudinary.com/fjplaurr/image/upload/v1590711759/Fly-flowers/61_qpy4jd.jpg',
    collection: 'colorful',
    trending: false,
    recommended: false,
  },
];
